<template>
    <div>
        <Header :title="title"></Header>
        <div class="container pb-5">
            <!-- <div class="py-3">
                <i class="fal fa-chevron-left close" @click="$router.go(-1)"></i>
            </div> -->
            <div class="py-5">
                <div class=" px-3">
                    <i class="fas fa-bell-exclamation fa-3x mb-3 text-danger"></i>
                    <h5 class="mb-2 fw-bold">아래 경우 <b class="text-danger">주차권 사용이 불가</b>합니다.
                    </h5>
                    <p>주차권이 취소되고, 전액 현장 요금이 발생합니다.</p>
                </div>
            </div>
            <ul class="pb-5">
                <li class="mb-2">ㆍ입차 후에 주차권 구매</li>
                <li class="mb-2">ㆍ출차 없이 주차권 2개 이상 연속 사용</li>
                <li class="mb-2">ㆍ주차권 유효시간 외 주차</li>
                <li class="mb-2">ㆍ한번 출차 후 재입차</li>
            </ul>
            
            
            <div class="position-fixed bottom-0 start-0 w-100 flex-between-center flex-column">
                <div class="text-gray-relative-500 lh-1 mb-3 w-100 px-3 pb-2">
                    <p class="mb-2 small">
                        - 유의사항 미숙지로 인한 불이익은 책임지지 않습니다.
                    </p>
                    <p class="small">
                        - 만차 및 현장 사정으로 주차가 어려울 수 있습니다.
                    </p>
                </div>
                <div class="btn btn-primary w-100 rounded-0 thick" @click="$router.push(`/reservation-confirm/${item_id}/${item_idx}`)">
                    위 내용을 확인했습니다.
                </div>
            </div>
        </div>
        <InfoPopup ref="InfoPopup" />
        
        <Loading v-if="loading" :msg="msg"/>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
import InfoPopup from '@/components/reservation/InfoPopup.vue'
import Loading from '@/components/common/Loading.vue'
export default {
    components:{Header,InfoPopup,Loading},
    data(){
        return{
            idx: 0,
            items: [
                {
                    id: 0,
                    img: '',
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: '',
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: '',
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: '',
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],

            address: '서울 강남구 도산대로 524',
            msg:'Loading...',
            date: '2023년 09월 20일',
            start_time: "16:30",
            end_time: "23:59",
            check1: true,
            payment_method: null,

            timeout_id:null,
            loading : false,

            // front ui
			timepicker_pp: false,
			payment_pp: false,
        }
    },
    computed:{
        item_id(){
            return this.$route.params.id;
        },
        item_idx(){
            return this.$route.params.idx;
        },
        title(){
            return this.items[this.item_id].title;
        },
    },
    created(){
        // eventBus.pageTitle(this.title);
    },
    methods:{
        loadingFn(txt){
            if(txt){
                this.msg=txt;
            }else{
                this.msg='Loading...'
            }
            this.loading = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }
            if( agent.indexOf('android') > -1 ) {
                window.open("wonsungpay://paylink") 
            }
            this.timeout_id = setTimeout(() => {
                this.loading = false;
                this.$router.push('/receipt/1');
            }, 1500);
        },
    }
}
</script>

<style lang="scss" scoped>
hr{
    border-color:#eee;
}
i.close{
    font-size: 1.2em;
    margin-right: auto;
}
.container{
    //min-height: 100vh;
}
</style>