<template>
    <div>
        <Header :title="title" class="bg-primary text-white"></Header>
        <div class="scroll-area" @scroll="handleScroll" ref="scrollArea" :style="{ paddingTop: responsibleAreaPadding+'px'}">
            <div class="container responsible-area" :class="{'animation-on':overScrollY}"
            :style="{ height: responsibleAreaHeight + 'px' }">
                <div class="text-center text-white">
                    <h4 class="mb-2">{{items[item_id].sell[item_idx].title}} {{items[item_id].sell[item_idx].price.toLocaleString()}}원</h4>
                    <small class="text-sub animation-hidden">구매당일 00:00~23:59 사이 주차가능
                    </small>
                </div>
                
                <div class="position-relative bg-body mx-auto shadow p-3 text-center rounded-500 animation-hidden w-100">
                    <h6 class="fw-bold mb-2">결제 전 유의사항</h6>
                    <small>당일 유효시간 내 1시간 이용 가능
                    </small>
                </div>
            </div>
            <div class="p-3" :class="{'animation-on':overScrollY}">
                <div class="mt-3 mb-2 w-100 bg-img h-px-200 rounded-500" :style="`background-image: url(${require('@/assets/img/sample_park.jpg')})`"></div>
                <div class="py-3">
                    입출차 주의사항
                    <ul class="fs-px-12 pt-2 mb-4">
                        <li>ㆍ당일 유효시간 내 1시간 이용 가능</li>
                        <li>ㆍ출차 방법 : 현장 관리자 주차권 확인</li>
                        <li>ㆍ마이주차장 주차권 외 할인 적용 불가</li>
                        <li>ㆍ사전정산기 사용 시 주차권 적용 불가</li>
                    </ul>
                    안내사항
                    <ul class="fs-px-12 pt-2 mb-5">
                        <li>ㆍ만차 혹은 현장 사정에 따라 주차가 어려울 수 있음</li>
                        <li>ㆍ선불주차권으로 입차한 상태에서 구매 시 사용 불가</li>
                        <li>ㆍ입출차는 1회만 가능하며, 주차권으로 출차 후 재입차 시 현장요금 적용
                        </li>
                        <li class="mb-3">ㆍ주차권 2개 이상 연속 사용 및 연박 주차 시 총 이용시간에 대해 전액 현장요금 적용
                        </li>
                        <li>ex) 당일권+당일권, 1시간권 +2시간권 불가  </li>
                        <li>ㆍ 주차권 구매 후 입차 시 주차권 환불 불가</li>
                        <li>ㆍ구매 당일 동일 주차권 동일 차량번호로 재구매 불가</li>
                        <li>ㆍ주차장에서의 도난, 분실, 사고는 일체 책임지지 않습니다</li>
                    </ul>

                    이 주차장의 다른 주차권
                    <ul class="mt-3">
                        <li class="p-2 rounded-3 mb-2 flex-between-center" :class="{'ticket_abled':item.available, 'ticket_disabled':!item.available}" @click="GoReservation(item.available, item_id, index)" v-for="(item, index) in items[item_id].sell" :key="index">
                            <div class="p-1">
                                <h5 class="mb-1">{{item.title}}</h5>
                                <small class="text-sub">당일 23:59까지 주차 가능</small>
                            </div>
                            <div class="p-1">
                                <h5 class="fw-bold">{{item.price.toLocaleString()}}원 <i class="far fa-chevron-right ms-2"></i></h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="position-fixed start-0 bottom-0 w-100">
                <div class="bg-gray-relative-200 py-3 text-center text-gray-relative-600 fs-px-14">
                    주차장에서 발생한 사고는 일체 책임지지 않습니다.<br>현장 사정에 따라 주차가 어려울 수 있습니다.

                </div>
                <div class="btn btn-primary w-100 rounded-0 thick h5" @click="Purchase()">
                    구매하기
                </div>
            </div>
        </div>

        <div v-show="timepicker_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="timepicker_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4">입차시간 선택</h5>
                        <div class="px-2 flex-between-center pb-3">
                            <input type="time" v-model="start_time" class="form-control">
                            <div class="px-2">~</div>
                            <input type="time" v-model="end_time" class="form-control">
                        </div>
                        <div class="btn btn-primary w-100" @click="timepicker_pp = false">닫기</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="payment_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="payment_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4">결제수단 선택</h5>
                        <div class="px-2">
                            <ul>
                                <li class="h5 py-3" @click="payment_method = '휴대폰 결제'; payment_pp = false">
                                    <i class="w-px-20 text-center fal fa-mobile text-gray-relative-500 me-3"></i>
                                    <span>휴대폰 결제</span>
                                </li>
                                <li class="h5 py-3 flex-start-center" 
                                v-for="(item,index) in cards" :key="index"
                                @click="payment_method = `${item.name} ${item.number1}-******-${item.number3}`; payment_pp = false">
                                    <i class="w-px-20 text-center fal fa-credit-card text-gray-relative-500 me-3"></i>
                                    <div>
                                        <p>{{item.name}}</p>
                                        <span>{{item.number1}}-******-{{item.number3}}</span>
                                    </div>
                                </li>
                                <li class="h5 py-3 flex-start-center" @click="addcard_pp = true">
                                    <p class="text-gray-relative-500"><i class="fal fa-plus me-3"></i> 새 카드추가</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="addcard_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="addcard_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4">카드추가</h5>
                        <div class="px-2">
                            <div class="flex-around-center gap-1 mb-3">
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_1">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_2">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_3">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_4">
                            </div>
                            <div class="flex-around-center gap-1 col-8 mb-3">
                                <input type="text" placeholder="월" class="form-control" v-model="addcard_month">
                                <input type="text" placeholder="년" class="form-control" v-model="addcard_year">
                            </div>
                            <div class="flex-between-center gap-2">
                                <button class="w-100 btn bg-gray-relative-200" @click="addcard_pp = false">취소</button>
                                <button class="w-100 btn btn-primary text-white" @click="AddCard()">추가</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            overScrollY:false,
            items: [
                {
                    id: 0,
                    img: '',
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: '',
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: '',
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: '',
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],

            start_time: "",
            end_time: "",
            check1: true,
            payment_method: null,

            addcard_number_1: null,
            addcard_number_2: null,
            addcard_number_3: null,
            addcard_number_4: null,
            addcard_month: null,
            addcard_year: null,

            cards:[
                {
                    name: '현대카드',
                    number1: 541085,
                    number2: '******', 
                    number3: 3167,
                },
            ],

            // front ui
			timepicker_pp: false,
			payment_pp: false,
            addcard_pp: false,
            responsibleAreaHeight: 200,
            responsibleAreaPadding: 170,
        }
    },
    computed:{
        item_id(){
            return this.$route.params.id;
        },
        item_idx(){
            return this.$route.params.idx;
        },
        title(){
            return this.items[this.item_id].title;
        },
    },
    created(){
        // eventBus.pageTitle(this.title);
    },
    methods:{
        handleScroll() {
            const scrollArea = this.$refs.scrollArea;

            const scrollPosition = scrollArea.scrollTop;
            this.responsibleAreaHeight = Math.max(50, 200 - scrollPosition);
            this.responsibleAreaPadding = Math.max(0, 170 - scrollPosition);

            this.overScrollY = scrollPosition >= 220;
        },
        AddCard(){
            let obj = {
                name: 'NewCard',
                number1: this.addcard_number_1,
                number2: this.addcard_number_2,
                number3: this.addcard_number_3,
                number4: this.addcard_number_4,
            }
            this.cards.push(obj);
            this.InitAddCard();
            this.addcard_pp = false;
        },
        InitAddCard(){
            this.addcard_number_1 = null;
            this.addcard_number_2 = null;
            this.addcard_number_3 = null;
            this.addcard_number_4 = null;
            this.addcard_month = null;
            this.addcard_year = null;
        },
        Purchase(){
            console.log(this.item_idx);
            this.$router.push(`/reservation-detail/${this.item_id}/${this.item_idx}`)
        },
        
        GoReservation(available, item_id, index){
            if(available){
                this.$router.push(`/reservation/${item_id}/${index}`)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
hr{
    border-color:#eee;
}
.scroll-area{
    position: absolute;
    top: 58px;
    height: calc(100vh - 58px);
    overflow-y: auto;
    padding-bottom: 127px;
    width: 100%;
    max-width: 768px;
}
.responsible-area {
    position: fixed;
    top: 57px;
    width: 100%;
    max-width: 768px;
    transition: all .3s;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background:linear-gradient(0deg, rgb(255, 255, 255) 23%, rgb(85, 139, 255) 23%)
}
.responsible-area.animation-on{
    top: 58px;
    width: 100%;
    background:linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(85, 139, 255) 0%);
    //height: 50px;
    .animation-hidden{
        display: none;
    }
    h4{
        font-size: 16px !important;
    }
}
</style>