<template>
    <div>
        <div class="container bg-gray-relative-100 pb-5">
            <div class="py-3">
                <i class="fal fa-chevron-left close" @click="$router.go(-1)"></i>
            </div>
            <div class="py-5">
                <div class="text-center">
                    <h4 class="mb-2 ">{{title}}</h4>
                    <p>{{address}}</p>

                    <div class="text-gray-relative-500 mt-4 small" @click="GoDetail()">자세히보기 <i class="fal fa-chevron-right"></i></div>
                </div>
            </div>
            <div class="pb-5">
                <div class="bg-body p-4 rounded-400">
                    <small class="text-gray-relative-500">주차권명</small>
                    <h5 class="mb-4 mt-1">{{items[item_id].sell[item_idx].title}}</h5>
                    <small class="text-gray-relative-500">일시</small>
                    <h5 class="text-primary mt-1">{{date}} {{start_time}} ~ {{end_time}}</h5>
                </div>
            </div>
            <div>
                <div class="text-gray-relative-500 lh-1">
                    <p class="mb-2">주차안내</p>
                    <p class="mb-1 small">
                        - 차단기가 자동으로 열리고 닫힙니다.
                    </p>
                    <p class="mb-1 small">
                        - 출차 후 재주차 불가합니다.
                    </p>
                    <p class="small">
                        - 예약시간 1시간 후 주차확인합니다.
                    </p>
                </div>
            </div>
            <div class="position-fixed bottom-0 start-0 w-100 flex-between-center">
                <div class="bg-white text-danger btn w-50 rounded-0 thick" @click="loadingFn('환불 처리중입니다...')">
                    환불신청
                </div>
                <div class="btn btn-primary w-50 rounded-0 thick" @click="loadingFn()">
                    영수증확인
                </div>
            </div>
        </div>
        <InfoPopup ref="InfoPopup" />
        
        <Loading v-if="loading" :msg="msg"/>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Header from '@/components/common/Header.vue'
import InfoPopup from '@/components/reservation/InfoPopup.vue'
import Loading from '@/components/common/Loading.vue'
export default {
    components:{Header,InfoPopup,Loading},
    data(){
        return{
            items: [
                {
                    id: 0,
                    img: '',
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: '',
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: '',
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: '',
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],

            address: '',
            msg:'Loading...',
            date: '2023년 09월 20일',
            start_time: "09:30",
            end_time: "11:30",
            check1: true,
            payment_method: null,

            timeout_id:null,
            loading : false,

            // front ui
			timepicker_pp: false,
			payment_pp: false,
        }
    },
    computed:{
        item_id(){
            return this.$route.params.id;
        },
        item_idx(){
            return this.$route.params.idx;
        },
        title(){
            return this.items[this.item_id].title;
        },
    },
    created(){
        // eventBus.pageTitle(this.title);
        this.idx = this.$route.params.idx;
    },
    methods:{
        loadingFn(txt){
            if(txt){
                this.msg=txt;
            }else{
                this.msg='Loading...'
            }
            this.loading = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.loading = false;
                this.$router.push('/receipt/1');
            }, 1500);
        },
        GoDetail(){
            this.$refs.InfoPopup.item_id = this.item_id; 
            this.$refs.InfoPopup.no_animation = true; 
            this.$refs.InfoPopup.FullPaged(); 
            this.$refs.InfoPopup.pp = true;
        }
    }
}
</script>

<style lang="scss" scoped>
hr{
    border-color:#eee;
}
i.close{
    font-size: 1.2em;
    margin-right: auto;
}
.container{
    min-height: 100vh;
}
</style>