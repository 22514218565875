<template>
    <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
        <div class="receipt">
            <div class="bg-body p-3 rounded-3 pb-4">
                
                <div class="py-4 text-center">
                    <h4 class="fw-bold">매출전표</h4>
                    <p class="small text-gray-relative-500 lh-sm mt-2">{{item.number}}</p>
                </div>

                <div>
                    <div class="border border-gray-relative-700"></div>
                    <h5 class="py-3">결제정보</h5>
                    <div class="cutting_line border-gray-relative-500 pb-4"></div>
                </div>
                <div class="">
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">결제수단</p>
                            <p class="w-100 text-end">{{item.payment_method}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">거래상태</p>
                            <p class="w-100 text-end">{{item.status}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">거래일시</p>
                            <p class="w-100 text-end">{{item.approve_time}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2" v-if="item.status == '취소'">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">취소일시</p>
                            <p class="w-100 text-end text-danger">{{item.cancel_time}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">결제카드</p>
                            <p class="w-100 text-end">현대카드</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">카드번호</p>
                            <p class="w-100 text-end">541085-******-3167</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">할부개월</p>
                            <p class="w-100 text-end">3개월</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">승인번호</p>
                            <p class="w-100 text-end">3298107</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">승인금액</p>
                            <p class="w-100 text-end">{{(item.item_price).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between pt-2 px-2 pb-2 bg-gray-relative-100">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">┕ 과세금액</p>
                            <p class="w-100 text-end">{{(item.item_price*0.9).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between pb-2 px-2 mb-2 bg-gray-relative-100">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">┕ 부가세</p>
                            <p class="w-100 text-end">{{(item.item_price*0.1).toLocaleString()}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">면세금액</p>
                            <p class="w-100 text-end">0</p>
                        </li>
                    </ul>


                    <div class="mt-5">
                        <div class="border border-gray-relative-700"></div>
                        <h5 class="py-3">공급자 정보</h5>
                        <div class="cutting_line border-gray-relative-500 pb-4"></div>
                    </div>
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">상호</p>
                            <p class="w-100 text-end">{{shop.title}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">대표자명</p>
                            <p class="w-100 text-end">{{shop.representative_name}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">연락처</p>
                            <p class="w-100 text-end">{{shop.contact}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">사업자번호</p>
                            <p class="w-100 text-end">{{shop.business_number}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">주소</p>
                            <p class="w-100 text-end">{{shop.addr}}</p>
                        </li>
                    </ul>

                    
                    <div class="mt-5">
                        <div class="border border-gray-relative-700"></div>
                        <h5 class="py-3">결제대행사 정보</h5>
                        <div class="cutting_line border-gray-relative-500 pb-4"></div>
                    </div>
                    <ul class="small">
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">상호</p>
                            <p class="w-100 text-end">{{agency.title}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">대표자명</p>
                            <p class="w-100 text-end">{{agency.representative_name}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">사업자번호</p>
                            <p class="w-100 text-end">{{agency.business_number}}</p>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <p class="flex-shrink-0 text-gray-relative-500 me-3">주소</p>
                            <p class="w-100 text-end">{{agency.addr}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-between-center mt-4">
                <!-- <div class="form-control thick bg-gray-relative-300 border-gray-relative-300 w-60 fs-6 text-center me-2" @click="Cancel()" v-if="item.status != '취소'">
                    결제 취소
                </div> -->
                <router-link to="/main/misa" class="btn thick btn-primary w-100 text-white">
                    확인
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
            item_list: [
                {
                    name:'평일 당일권',
                    price:9000,
                },
            ],

            item: {
                number: '20231016-3125172',
                item_title: '',
                item_price: 0,
                payment_method: '신용카드',
                user_name: '김현수',
                user_contact: '01031245848',
                status: '승인',
                approve_time: '2023.10.16 13:52:22',
                cancel_time: '2023.10.16 14:03:21',
            },
            shop: {
                title: '마이파킹',
                representative_name: '김혜숙',
                contact: '010-4221-8571',
                business_number: '108-12-32765',
                addr: '경기도 하남시 미사대로 1689번길 69',
            },
            agency: {
                title: '원성글로벌',
                representative_name: '장주현',
                business_number: '107-52-31792',
                addr: '경기도 하남시 미사대로520, CA동 804호',
            }
        }
    },
    methods:{
        Cancel(){
            this.$confirm('결제를 취소하시겠습니까?').then((result)=>{
                if( result.isConfirmed ){
                    this.$alert('결제를 취소했습니다').then(()=>{
                        this.$router.push('/home');
                    });
                }
            })
        },
        PurchasedItem(){
            let _item_title = '';
            let _item_price = 0;

            for( let i=0; i<this.item_list.length; i++ ){
                if( i != this.item_list.length-1){
                    _item_title += this.item_list[i].name + ",<br>"
                }else{
                    _item_title += this.item_list[i].name
                }

                _item_price += this.item_list[i].price;
            }

            this.item.item_title = _item_title;
            this.item.item_price = _item_price;
        }
    },
    mounted(){
        this.PurchasedItem();
    }
}
</script>
