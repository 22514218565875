<template>
    <div>
        <Header :title="title"></Header>
        <div class="container">
            <div class="bg-primary p-3 rounded text-white">
                <h4 class="mb-3 py-2">{{items[item_id].sell[item_idx].title}}</h4>
                <hr>
                <div class="flex-between-center mb-3">
                    <span class="text-sub">입차시간</span>
                    <h4 v-if="entry_time == ''" @click="timepicker_pp = true">
                        <span class="text-sub me-2">예상 시간 설정</span>
                        <i class="fas fa-caret-down"></i>
                    </h4>
                    <h4 class="flex-start-center" v-else @click="timepicker_pp = true"><span>{{entry_time}} ~ {{entry_end_time}}</span> <i class="fas fa-sort-down pb-2 ms-2 small"></i></h4>
                </div>
                <div class="flex-between-center">
                    <span class="text-sub w-30">차량번호</span>
                    <div class="flex-end-center w-70">
                        <input type="text" class="text-end p-1 bg-transparent text-body-i" value="48너7136" :disabled="!edit_number" :class="{'border':edit_number, 'border-0':!edit_number}">
                        <i class="fas fa-pencil ms-2 fs-px-13" @click="edit_number = !edit_number"></i>
                    </div>
                </div>
            </div>
            
            <div class="py-3">
                <div class="flex-between-center mb-3">
                    <span class="">쿠폰</span>
                    <div class="flex-start-center" @click="coupon_pp = true" v-if="coupon_type ==null">쿠폰선택 <i class="fas fa-sort-down pb-2 ms-2 small"></i></div>
                    <div class="flex-start-center" @click="coupon_pp = true" v-else>{{coupon_type}} <i class="fas fa-sort-down pb-2 ms-2 small"></i></div>
                </div>
                <div class="flex-between-center mb-3">
                    <span class="">충전금</span>
                    <div class="flex-start-center">보유 0P <button class="btn btn-sm btn-primary ms-2 border-0">충전</button></div>
                </div>
                <div class="round_checkbox mt-3 fs-px-18 flex-between-center">
                    <input type="checkbox" v-model="check1" id="check1">
                    <label for="check1">
                        <div class="chk_icon me-2 w-px-20 h-px-20"></div>
                        <span class="conditions flex-shrink-0 lh-1">영수증신청</span>
                    </label>
                    <div class="text-truncate">
                        user123@email.com
                    </div>
                </div>
            </div>
            <div class="position-fixed start-0 bottom-0 w-100">
                <p class="text-decoration p-3 text-danger" @click="$router.go(-1)"><i class="fas fa-bell-exclamation me-2"></i>잠깐! 유의사항을 확인해 주세요.</p>
                <div class="bg-gray-relative-100 p-3 pb-2">
                    <div class="flex-between-center">
                        <span class="text-gray-relative-600 me-2">결제금액</span>
                        <span class="h5">
                            <span>{{items[item_id].sell[item_idx].price.toLocaleString()}}원</span>
                        </span>
                    </div>
                </div>
                <div class="bg-gray-relative-100 p-3">
                    <div class="flex-between-center">
                        <span class="text-gray-relative-600 flex-shrink-0 me-2">결제수단</span>
                        <span class="h5 text-truncate">
                            <span class="flex-start-center text-gray-relative-500" @click="payment_pp = true" v-if="payment_method == null">
                                <span>결제수단선택</span>
                                <i class="fas fa-sort-down pb-2 ms-2 small"></i>
                            </span>
                            <span class="flex-start-center text-gray-relative-500" @click="payment_pp = true" v-else>
                                <span class="text-truncate">{{payment_method}}</span>
                                <i class="fas fa-sort-down pb-2 ms-2 small"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="btn btn-primary w-100 rounded-0 thick h5" @click="Purchase()">
                    구매하기
                </div>
            </div>
        </div>

        <div v-show="timepicker_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="timepicker_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4 text-center">입차 예정 시간을 선택해주세요</h5>
                        <div class="px-2 flex-between-center pb-3">
                            <!-- <input type="time" v-model="start_time" class="form-control">
                            <div class="px-2">~</div>
                            <input type="time" v-model="end_time" class="form-control"> -->
                            <ul class="w-100 h-px-350 overflow-auto text-center h5">
                                <li v-for="(item,index) in entry_time_list" :key="index" class="py-px-12 rounded" 
                                @click="entry_time_idx = index; entry_time = item.entry_time; entry_end_time = item.entry_end_time" :class="{'bg-sub':entry_time_idx == index}">
                                    {{item.entry_time}} ~ {{item.entry_end_time}}
                                </li>
                            </ul>
                        </div>
                        <div class="btn btn-primary w-100" @click="timepicker_pp = false">닫기</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="coupon_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="coupon_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4 ">쿠폰을 선택해주세요</h5>
                        <div class="px-2">
                            <ul>
                                <li class="h5 py-3 flex-start-center" @click="coupon_type = null; coupon_pp = false">
                                    <p class="text-gray-relative-500"><i class="fal fa-times me-3"></i>적용 안함</p>
                                </li>
                                <li class="h5 py-3 flex-start-center" @click="coupon_type = '10% 할인 쿠폰'; coupon_pp = false">
                                    <p class="text-gray-relative-500"><i class="fal fa-plus me-3"></i>10% 할인 쿠폰</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="payment_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="payment_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4">결제수단을 선택해주세요</h5>
                        <div class="px-2">
                            <ul>
                                <!-- <li class="h5 py-3" @click="payment_method = '휴대폰 결제'; payment_pp = false">
                                    <i class="w-px-20 text-center fal fa-mobile text-gray-relative-500 me-3"></i>
                                    <span>휴대폰 결제</span>
                                </li> -->
                                <li class="h5 py-3" @click="payment_method = '굿페이 결제'; payment_pp = false">
                                    <i class="w-px-20 text-center fal fa-mobile text-gray-relative-500 me-3"></i>
                                    <span>굿페이 결제</span>
                                </li>
                                <li class="h5 py-3 flex-start-center" 
                                v-for="(item,index) in cards" :key="index"
                                @click="payment_method = `${item.name} ${item.number1}-******-${item.number3}`; payment_pp = false">
                                    <i class="w-px-20 text-center fal fa-credit-card text-gray-relative-500 me-3"></i>
                                    <div>
                                        <p>{{item.name}}</p>
                                        <span>{{item.number1}}-******-{{item.number3}}</span>
                                    </div>
                                </li>
                                <li class="h5 py-3 flex-start-center" @click="addcard_pp = true">
                                    <p class="text-gray-relative-500"><i class="fal fa-plus me-3"></i> 새 카드추가</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="addcard_pp">
            <div class="basic_pp bottom_up">
                <div class="dimm" @click="addcard_pp = false"></div>
                <div class="content bg-body shadow p-3 pt-4">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4">카드추가</h5>
                        <div class="px-2">
                            <div class="flex-around-center gap-1 mb-3">
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_1">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_2">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_3">
                                -
                                <input type="number" placeholder="0000" class="form-control" v-model="addcard_number_4">
                            </div>
                            <div class="flex-around-center gap-1 col-8 mb-3">
                                <input type="text" placeholder="월" class="form-control" v-model="addcard_month">
                                <input type="text" placeholder="년" class="form-control" v-model="addcard_year">
                            </div>
                            <div class="flex-between-center gap-2">
                                <button class="w-100 btn bg-gray-relative-200" @click="addcard_pp = false">취소</button>
                                <button class="w-100 btn btn-primary text-white" @click="AddCard()">추가</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="purchaseError">
            <div class="basic_pp">
                <div class="dimm" @click="purchaseError = false"></div>
                <div class="position-fixed w-80 start-50 top-50 translate-middle bg-body rounded-3 shadow p-3 pt-4 flex-center-center">
                    <div class="body overflow-auto" ref="basic_pp_body">
                        <h5 class="px-2 mb-4 text-center">마이주차</h5>
                        <div class="px-2">
                            <div class="flex-around-center mb-3">
                                입차시간 또는 결제 수단을 선택해주세요. 
                            </div>
                            <div class="flex-between-center">
                                <button class="w-100 btn btn-primary text-white" @click="purchaseError = false;">확인</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Loading v-if="loading" :msg="msg"/>
    </div>
</template>

<script>
import { eventBus } from '../../main'
import Loading from '@/components/common/Loading.vue'
import Header from '@/components/common/Header.vue'
export default {
    components:{Header,Loading},
    data(){
        return{
            timeout_id:null,
            msg:'Loading...',
            loading:false,

            start_time: "",
            end_time: "",

            edit_number: false,

            
            items: [
                {
                    id: 0,
                    img: '',
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: '',
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: '',
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: '',
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],

            entry_time_list: [],
            entry_time_idx: null,
            entry_time: "",
            entry_end_time:"",

            check1: true,
            payment_method: null,
            coupon_type: null,

            addcard_number_1: null,
            addcard_number_2: null,
            addcard_number_3: null,
            addcard_number_4: null,
            addcard_month: null,
            addcard_year: null,

            cards:[
                {
                    name: '현대카드',
                    number1: 541085,
                    number2: '******', 
                    number3: 3167,
                },
            ],

            purchaseError: false,
            // front ui
			timepicker_pp: false,
			coupon_pp: false,
			payment_pp: false,
            addcard_pp: false,
        }
    },
    created(){
        // eventBus.pageTitle(this.title);

        this.SetEntryTimeList();
    },
    computed:{
        item_id(){
            return this.$route.params.id;
        },
        item_idx(){
            return this.$route.params.idx;
        },
        title(){
            return this.items[this.item_id].title;
        },
    },
    methods:{
        AddCard(){
            let obj = {
                name: 'NewCard',
                number1: this.addcard_number_1,
                number2: this.addcard_number_2,
                number3: this.addcard_number_3,
                number4: this.addcard_number_4,
            }
            this.cards.push(obj);
            this.InitAddCard();
            this.addcard_pp = false;
        },
        InitAddCard(){
            this.addcard_number_1 = null;
            this.addcard_number_2 = null;
            this.addcard_number_3 = null;
            this.addcard_number_4 = null;
            this.addcard_month = null;
            this.addcard_year = null;
        },
        Purchase(){
            // this.$router.push(`/reservation-detail/${this.idx}`)
            if(this.entry_time !== '' && this.payment_method!==null){
                this.msg='Loading...'
                this.loading = true;

                if (this.timeout_id) {
                    clearTimeout(this.timeout_id);
                }
                
                var agent = navigator.userAgent.toLowerCase();

                if( agent.indexOf('android') > -1 ) {
                    window.open("wonsungpay://paylink") 
                }

                this.timeout_id = setTimeout(() => {
                    this.loading = false;
                    this.$router.push('/receipt/1');
                }, 1500);    
            }else{
                this.purchaseError = true;
            }
            
        },
        SetEntryTimeList(){
            let list = [];

            let time = new Date("1970-01-01 00:00");
            // let mins_30 = 30 * 60 * 1000;
            let mins_60 = 60 * 60 * 1000;

            for( let i=0; i<24; i++ ){
                let obj = {
                    idx: 0,
                    entry_time: "",
                    entry_end_time: "",
                }

                obj.idx = i;
                obj.entry_time = this.TimeForm(time);
                time.setTime( time.getTime() + mins_60);
                obj.entry_end_time = this.TimeForm(time);

                list.push(obj);
            }

            this.entry_time_list = list;
        },
        TimeForm(date){
            let h = date.getHours();
            let m = date.getMinutes();

            let _h = h < 10 ? "0"+h : h;
            let _m = m < 10 ? "0"+m : m;

            return _h+":"+_m
        }
    }
}
</script>

<style lang="scss" scoped>
hr{
    border-color:#eee;
}
.text-decoration{
    text-decoration: underline;
}
</style>